<template>
  <main>
    <section class="container-fluid">
      <Navbar black active="about" @localLoaded="setlocalLoaded" />
    </section>
    <div class="about container mt-5" v-if="localLoaded">
      <div class="row">
        <div class="col">
          <h2>
            {{ $t('aboutInfo.title') }}
          </h2>
        </div>
      </div>
      <div class="row mt-4 gx-5">
        <div class="col-sm-7" v-html="$t('aboutInfo.description')"></div>
        <div class="col-sm-5">
          <img :src="getUrl + $t('aboutInfo.image.path')" class="img-fluid" alt="..." />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <div
            class="about__card p-5 mt-3"
            v-for="(cart, index) in $t('aboutBadge').carts"
            :key="`cart-${index}`"
          >
            <div class="row">
              <div class="col-sm-2">
                <img
                  :src="getUrl + cart.value.icon.path"
                  class="about__card-icon img-fluid"
                  alt="clients"
                />
              </div>
              <div class="col-sm-10">
                <div class="about__card-content">
                  <h4 class="about__card-title">
                    {{ cart.value.title }}
                  </h4>
                  <p class="about__paragraph mt-4">
                    {{ cart.value.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col mt-5">
          <h2>
            {{ $t('aboutLicenses.title') }}
          </h2>
        </div>
      </div>
      <div class="row mt-4 images-wrapper">
        <CoolLightBox :items="items" :index="index" @close="index = null"> </CoolLightBox>

        <div class="col-sm-3 mt-3" v-for="(image, imageIndex) in items" :key="imageIndex">
          <div
            class="image"
            @click="index = imageIndex"
            :style="{
              backgroundImage: 'url(' + image + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center'
            }"
          ></div>
        </div>
      </div>
    </div>
    <Footer />
  </main>
</template>

<script>
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import enviroment from '@/mixins/enviroment';

export default {
  mixins: [enviroment],
  metaInfo() {
    const title = this.$t('mainTitle');
    return {
      title,
      titleTemplate: '%s | atcbrok.kz'
    };
  },
  name: 'About',
  components: {
    Navbar,
    Footer,
    CoolLightBox
  },
  data() {
    return {
      items: [
        'https://www.lotus-qa.com/wp-content/uploads/2020/02/testing.jpg',
        'https://www.lotus-qa.com/wp-content/uploads/2020/02/testing.jpg',
        'https://www.lotus-qa.com/wp-content/uploads/2020/02/testing.jpg',
        'https://www.lotus-qa.com/wp-content/uploads/2020/02/testing.jpg'
      ],
      index: null,
      localLoaded: false
    };
  },
  methods: {
    normalizeItems() {
      this.items = this.$t('aboutLicenses').licenses.map(
        item => this.getUrl + item.value.icon.path
      );
    },
    setlocalLoaded() {
      this.localLoaded = true;
      this.normalizeItems();
    }
  }
};
</script>

<style lang="scss">
.about {
  &__paragraph {
    font-size: 1.25rem;
  }

  .images-wrapper {
    display: flex;
  }

  .image {
    height: 20.563rem;
    cursor: pointer;
  }

  &__card {
    background-color: #f8f8f8;
    border-radius: 0.625rem;

    &-title {
      font-family: 'Fira Sans', sans-serif;
    }

    &-paragraph {
      font-size: 1.375rem;
    }
  }
}
</style>
